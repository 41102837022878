import { ToggleFeature } from "@/react-components/Common/FeatureToggle";
import { FavoriteProductHeart } from "@/react-components/Favorites/FavoriteProductHeart";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery";
import { hasValue } from "@xxl/common-utils";
import type { USPData } from "@xxl/product-search-api";
import { XxlStack } from "react-app/src/components/Common/XxlStack";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { OverviewGridArea } from "../PdpBody/styles";
import { useReviewsQuery } from "../queries/useReviewsQuery";
import { Attributes } from "./Attributes";
import { AverageRating } from "./AverageRating";
import type { PriceProps } from "./Price";
import { Price } from "./Price";
import { ProductHeading } from "./ProductHeading";
import { SeeDescription } from "./SeeDescription";

type Props = {
  articleNumber: string;
  baseProductName: string;
  brandLogoUrl: string;
  brandName: string;
  brandUrl: string;
  handleRatingsClick?: () => void;
  isOutletProduct: boolean;
  onRatingsClick?: () => void;
  productStyle?: string;
  recommendationPercentage?: number | null;
  shouldDisplaySeeFullDescriptionLink?: boolean;
  seeFullDescriptionRedirectUrl?: string;
  shouldShowWriteReviewLink?: boolean;
  usps: USPData[];
  widget?: JSX.Element;
} & Omit<PriceProps, "productName">;

export const ProductOverview = ({
  articleNumber,
  baseProductName,
  brandLogoUrl,
  brandName,
  brandUrl,
  config,
  handleRatingsClick,
  isOutletProduct,
  priceData,
  productStyle,
  recommendationPercentage,
  salesUnit,
  shouldDisplaySeeFullDescriptionLink = true,
  seeFullDescriptionRedirectUrl,
  shouldShowWriteReviewLink = true,
  productCode,
  usps,
}: Props) => {
  const { data: reviewsQueryData } = useReviewsQuery(articleNumber);
  const hasRatings = reviewsQueryData?.aggregatedRating?.quantity ?? 0;
  const isMobile = useXxlMediaQuery("MobileMediaQuery");

  return (
    <OverviewGridArea
      spacing={
        isMobile ? xxlTheme.spaces.smallRegular : xxlTheme.spaces.regular
      }
    >
      <XxlStack
        flexDirection={"row"}
        justifyContent={"space-between"}
        marginTop={isMobile ? "12px" : "0px"}
      >
        <ProductHeading
          baseProductName={baseProductName}
          brandName={brandName}
          brandLogoUrl={brandLogoUrl}
          brandUrl={brandUrl}
        />
        {hasValue(productStyle) && (
          <FavoriteProductHeart productStyle={productStyle} isInPDP={true} />
        )}
      </XxlStack>
      <XxlStack
        direction="row"
        justifyContent="space-between"
        alignItems={"start"}
        gap={xxlTheme.spaces.extraLarge}
      >
        <Attributes usps={usps} />
        {hasRatings > 0 ? (
          <AverageRating
            onClick={handleRatingsClick}
            averageRating={reviewsQueryData?.aggregatedRating?.average ?? 0}
            shouldShowWriteReviewLink={shouldShowWriteReviewLink}
            recommendationPercentage={recommendationPercentage}
          />
        ) : null}
      </XxlStack>
      {shouldDisplaySeeFullDescriptionLink && (
        <SeeDescription
          seeFullDescriptionRedirectUrl={seeFullDescriptionRedirectUrl}
        />
      )}
      <ToggleFeature flag="toggle_videoly">
        <div id="videoly-videobox-placeholder"></div>
      </ToggleFeature>
      <Price
        config={config}
        isOutletProduct={isOutletProduct}
        priceData={priceData}
        salesUnit={salesUnit}
        productCode={productCode}
        productName={`${brandName}, ${baseProductName}`}
      />
    </OverviewGridArea>
  );
};
