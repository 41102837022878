import type { PriceType } from "@xxl/product-search-api";

const CASES = {
  SALE: "SALE",
  OUTLET: "OUTLET",
  MEMBER: "MEMBER",
} as const;

type TCases = keyof typeof CASES;

type TGetPriceCase = {
  price: {
    selling: number;
    alternate: number;
  };
  priceType: PriceType | "OUTLET";
};

const getPriceCase = ({ priceType, price }: TGetPriceCase) => {
  const { selling, alternate } = price;

  if (priceType === "OUTLET") {
    return CASES.OUTLET;
  }

  if (priceType === "REWARD") {
    return CASES.MEMBER;
  }

  if (selling < alternate) {
    return CASES.SALE;
  }

  return null;
};

type TGetTranslationKey = {
  priceCase: TCases | null;
};

const getPriceTranslationKey = ({ priceCase }: TGetTranslationKey) => {
  switch (priceCase) {
    case CASES.SALE:
      return "product.details.price.disclaimer.sale";
    case CASES.MEMBER:
      return "product.details.price.disclaimer.member";
    case CASES.OUTLET:
      return "product.details.price.disclaimer.outlet";
    default:
      return "general.error";
  }
};

export { getPriceCase, getPriceTranslationKey };
export type { TCases };
