import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { SanitizedHtmlComponent } from "@/react-components/Common";
import { DisclaimerInformation } from "@/react-components/DisclaimerInformation";
import { color } from "@xxl/theme";
import type { TCases } from "./helpers";
import { getPriceTranslationKey } from "./helpers";

type Props = {
  productName: string;
  previousPrice: string;
  priceCase: TCases;
};

const PriceDisclaimer = ({ priceCase, productName, previousPrice }: Props) => {
  const { t } = useTranslations();

  const translationKey = getPriceTranslationKey({ priceCase });
  const translation = t({
    key: translationKey,
    messageArguments: [productName, previousPrice],
  });

  return (
    <DisclaimerInformation
      color={color.black.hex}
      buttonText={t("general.close")}
      title={t("product.details.price.disclaimer.title")}
    >
      <SanitizedHtmlComponent text={translation} />
    </DisclaimerInformation>
  );
};

export { PriceDisclaimer };
